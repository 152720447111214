import styled from "@emotion/styled";

export const Container = styled.div`
  width: 100%;

  position: relative;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  padding: 12px 0;
  border-top: 1px solid ${(props) => props.theme.colors.lightGrey};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    align-items: flex-start;
  }
`;

export const Image = styled.div`
  flex-shrink: 0;

  display: inline-block;
  width: 68px;
  margin-right: 10px;
  margin-bottom: 15px;

  @media (min-width: 480px) {
    width: 75px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100px;
    margin-right: 20px;
    margin-bottom: 0;
  }
`;

export const Info = styled.div`
  flex: 1;

  display: inline-block;
  vertical-align: super;

  margin-bottom: 15px;

  font-size: 14px;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 18px;

    margin-bottom: 0;
  }
`;

export const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 8px;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 22px;
  }
`;

export const Price = styled.span`
  display: block;
  margin-bottom: 12px;
`;

export const Detail = styled.span`
  display: block;

  color: ${(props) => props.theme.colors.grey};

  > span {
    color: ${(props) => props.theme.colors.black};
  }

  line-height: 1.35em;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    line-height: 1.45em;
  }
`;

export const Delete = styled.button`
  flex-shrink: 0;

  margin-left: 24px;
  margin-right: 5px;

  background-color: transparent;
  border: none;
  text-decoration: underline;
  font-size: 12px;

  &:hover {
    cursor: pointer;
  }
`;
