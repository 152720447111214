import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { CHECKOUT_ROUTE } from "@routes";

import {
  Container,
  Data,
  DataRow,
  DataName,
  DataValue,
  Total,
} from "./CartSummary.styles";

import Button from "components/Button";

const CartSummary = ({ data, ivaPercentage, totalItems, totalClothes }) => {
  const messageTotal = `Total (${totalItems} artículo${
    totalItems > 1 ? "s" : ""
  }, ${totalClothes} prenda${totalClothes > 1 ? "s" : ""}):`;

  const beautifulNumbers = { ...data };
  Object.keys(beautifulNumbers).forEach((key) => {
    beautifulNumbers[key] = parseFloat(beautifulNumbers[key]).toFixed(2);
  });

  return (
    <Container>
      <Data>
        <DataRow>
          <DataName>Subtotal:</DataName>
          <DataValue>{`${beautifulNumbers.subtotal}€`}</DataValue>
        </DataRow>
        <DataRow>
          <DataName>I.V.A. ({ivaPercentage}%):</DataName>
          <DataValue>{`${beautifulNumbers.iva}€`}</DataValue>
        </DataRow>
        <DataRow>
          <DataName>Recargo de equivalencia:</DataName>
          <DataValue>{`${beautifulNumbers.recargo}€`}</DataValue>
        </DataRow>
        <DataRow>
          <DataName>Portes:</DataName>
          <DataValue>{`${beautifulNumbers.portes}€`}</DataValue>
        </DataRow>
      </Data>
      <Total>
        {messageTotal}&nbsp;<span>{beautifulNumbers.total}&euro;</span>
      </Total>
      <Link to={CHECKOUT_ROUTE}>
        <Button>Hacer pedido</Button>
      </Link>
    </Container>
  );
};

CartSummary.propTypes = {
  data: PropTypes.shape({
    subtotal: PropTypes.number.isRequired,
    iva: PropTypes.number.isRequired,
    recargo: PropTypes.number.isRequired,
    portes: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  ivaPercentage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  totalClothes: PropTypes.number.isRequired,
};

export default CartSummary;
