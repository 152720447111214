import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link } from "gatsby";

import { CATEGORIES_ROUTE } from "@routes";

import {
  getTotalPrice,
  getTotalNumberItems,
  getTotalNumberClothes,
  getUniqueCartItems,
} from "modules/cart";

import PrivateLayout from "layouts/PrivateLayout";

import BlankPage from "templates/BlankPage";

import PageTitle from "components/PageTitle";
import SeparatorBar from "components/SeparatorBar";
import { withShoppingCart } from "components/ShoppingCart";

import CartItem from "pagesComponents/Cart/CartItem";
import CartSummary from "pagesComponents/Cart/CartSummary";
import CartHelpText from "pagesComponents/Cart/CartHelpText";
import withAuth from "contexts/withAuth";

const Items = styled.ul`
  display: inline-block;
  width: 100%;
  max-width: 680px;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

const CartPage = (props) => {
  if (props.cart.length === 0) {
    return (
      <BlankPage title="Carrito">
        <p>No tiene nada en su carrito aún.</p>
        <Link
          style={{ display: "block", marginBottom: 30 }}
          to={CATEGORIES_ROUTE}
        >
          Ir de compras
        </Link>
        <SeparatorBar width={500} />
        <p>
          <b>Portes</b>
          <br />
          Para España peninsular y Baleares:
          <br />
          Hasta 150&euro; =&gt; 10&euro;
          <br />A partir de 150&euro; =&gt; gratis
        </p>
      </BlankPage>
    );
  }

  return (
    <PrivateLayout metaTitle="Carrito">
      <Cart {...props} />
    </PrivateLayout>
  );
};

export default withAuth(withShoppingCart(CartPage));

const Cart = ({ user, cart, removeFromCart }) => {
  // Summary info
  const subtotal = parseFloat(getTotalPrice(cart));
  const portes = subtotal >= 150 ? 0 : 10;
  const subtotalToTax = subtotal + portes;
  const iva = (user.fiscal.iva * subtotalToTax) / 100;
  const recargo = (user.fiscal.taxes * subtotalToTax) / 100;
  const total = subtotal + iva + recargo + portes;

  const totalItems = getTotalNumberItems(cart);
  const totalClothes = getTotalNumberClothes(cart);

  const uniqueCardItems = getUniqueCartItems(cart);

  return (
    <div>
      <PageTitle title="Carrito" />
      <Items>
        {uniqueCardItems.map((cartItem, index) => (
          <CartItem
            key={index}
            index={index}
            {...cartItem.data}
            removeFromCart={removeFromCart}
          />
        ))}
      </Items>
      <CartSummary
        data={{ subtotal, iva, recargo, portes, total }}
        ivaPercentage={user.fiscal.iva}
        totalItems={totalItems}
        totalClothes={totalClothes}
      />
      <CartHelpText />
    </div>
  );
};

Cart.propTypes = {
  user: PropTypes.shape({
    fiscal: PropTypes.object.isRequired,
  }).isRequired,
  cart: PropTypes.array,
  removeFromCart: PropTypes.func,
};
