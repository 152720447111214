import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import { customColorOption } from "@globals";

import {
  Container,
  Image,
  Info,
  Title,
  Price,
  Detail,
  Delete,
} from "./CartItem.styles";

const CartItem = ({
  index,
  title,
  price,
  size,
  color,
  customColors,
  quantity,
  image,
  removeFromCart,
}) => {
  const uniqueCustomColors = customColors.reduce((acc, currentCustomColor) => {
    const customColorObject = acc.find(
      (customColor) => customColor.value === currentCustomColor
    );

    if (customColorObject === undefined) {
      return [...acc, { value: currentCustomColor, count: 1 }];
    }

    return acc.map((customColor) => {
      if (customColor.value === currentCustomColor) {
        return { ...customColor, count: customColor.count + 1 };
      }

      return customColor;
    });
  }, []);

  return (
    <Container>
      <Image>
        <Img fluid={image.fluid} />
      </Image>
      <Info>
        <Title>
          {title} ({quantity} ud.)
        </Title>
        <Price>{price.toFixed(2)}&euro;</Price>

        <Detail>
          Talla:&nbsp;<span>{size}</span>
        </Detail>
        <Detail>
          {quantity > 1 ? "Colores" : "Color"}:&nbsp;
          <span>
            {color === customColorOption
              ? uniqueCustomColors
                  .map((uniqueCustomColor) => {
                    return `${uniqueCustomColor.value} (x${uniqueCustomColor.count})`;
                  })
                  .join(", ")
              : color}
          </span>
        </Detail>
      </Info>

      <Delete onClick={() => removeFromCart(index)}>Borrar</Delete>
    </Container>
  );
};

CartItem.propTypes = {
  index: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  customColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  quantity: PropTypes.number.isRequired,
  image: PropTypes.object.isRequired,
  removeFromCart: PropTypes.func.isRequired,
};

export default CartItem;
