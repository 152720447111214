import React from "react";

import { HelpText } from "./CartHelpText.styles";

import SeparatorBar from "components/SeparatorBar";

const CartHelpText = () => (
  <div>
    <HelpText>
      <b>Portes</b>
      <br />
      Para España peninsular y Baleares:
      <br />
      Hasta 150&euro; =&gt; 10&euro;
      <br />A partir de 150&euro; =&gt; gratis
    </HelpText>
    <SeparatorBar width={500} />
    <HelpText>
      Si tiene alguna duda:
      <br />
      <br />- Llámenos por teléfono al <b>651 639 581</b> (WhatsApp, FaceTime,
      Skype)
      <br />- O escríbanos a{" "}
      <a href="mailto:lavilaine.es@gmail.com">lavilaine.es@gmail.com</a>
    </HelpText>
  </div>
);

export default CartHelpText;
