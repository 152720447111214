import styled from "@emotion/styled";

export const HelpText = styled.p`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: block;

    margin-top: 20px;

    a {
      color: ${(props) => props.theme.colors.black};
    }
  }
`;
