import styled from "@emotion/styled";

export const Container = styled.div`
  max-width: 100%;
  background-color: ${(props) => props.theme.colors.lightGrey};

  padding: 12px 15px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;

  text-align: center;

  > button {
    background-color: white;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    float: right;
    width: 280px;
    margin-left: 20px;
  }
`;

export const Data = styled.div`
  display: block;
  text-align: left;
  padding: 0 10px 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid ${(props) => props.theme.colors.white};
`;

export const DataRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: 6px;
  }
`;

export const DataName = styled.span``;

export const DataValue = styled.span``;

export const Total = styled.span`
  display: block;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid ${(props) => props.theme.colors.white};

  > span {
    display: block;
    margin-top: 10px;
    font-size: 24px;
  }
`;
